"use client";

import type { Payload } from "@local/payload-client/src/types";
import { ContentBlockServer } from "../ContentBlock";
import ButtonServer, { ButtonIntent, ButtonStretch } from "../ui/button";
import { StarsWithReviews } from "../Reviews/StarsWithReviews";
import { IS_BAERSKIN, IS_HAM, MINISTORE } from "@/lib/const";
import type { RatingScores } from "../Reviews/Stars";
import { SingleIcon, SingleIconJustified } from "./Icon.client";
import { ArrowLeftIcon } from "../ui/icons";
import { RippedPageSvg } from "../ui/RippedPage";
import { barlowCondensed } from "@/utils/fonts";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./styles.module.css";
interface Props {
  block: Payload.ContentIconBlock;
}
const ContentIconBlockServer = ({
  block
}: Props) => {
  return <div className={block.darkBackground ? "text-white" : "text-gray-900"} data-sentry-component="ContentIconBlockServer" data-sentry-source-file="index.tsx">
      <ContentBlockServer className="flex flex-col items-center px-4 pt-8 pb-6 mb-4 md:px-20 lg:px-32" fullWidthBgColor={block.darkBackground ? "bg-gray-800" : "bg-gray-100"} data-sentry-element="ContentBlockServer" data-sentry-source-file="index.tsx">
        <h2 className="mb-4 text-3xl font-bold text-center">
          {block.headline}
        </h2>
        <p className="mb-6 text-lg font-normal leading-[1.333rem] text-center">
          {block.subtitle}
        </p>
        <ul className="grid grid-cols-2 gap-6 mb-8 md:gap-6 md:grid-cols-4">
          {block?.icons?.map(icon => <SingleIcon key={icon.id} icon={icon} />)}
        </ul>
        {block.ctaContent && block.ctaUrl && <>
            <ButtonServer content={block.ctaContent} stretch={ButtonStretch.full} url={block.ctaUrl} intent={ButtonIntent.CTA} className="mb-4 md:hidden md:invisible" />
            <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.CTA} className="invisible hidden mb-4 md:block md:visible" />
          </>}
        <div className="mx-auto mb-5 md:mx-0 max-w-fit md:max-w-none">
          <StarsWithReviews rating={parseFloat(block.starsRating as string) as RatingScores} reviews={block.reviewsAmount} vertical overrideColor={MINISTORE === "baerskintactical" ? "#1BB57C" : "#fbbf24"} dark={IS_HAM ? false : true} data-sentry-element="StarsWithReviews" data-sentry-source-file="index.tsx" />
        </div>
      </ContentBlockServer>
    </div>;
};
const ContentIconBlockServerJustified = ({
  block
}: Props) => {
  return <div className={`relative text-white -mt-4 ${barlowCondensed.className}`} data-sentry-component="ContentIconBlockServerJustified" data-sentry-source-file="index.tsx">
      <ContentBlockServer className="flex flex-col items-start px-4 pt-8 pb-4 mb-4 md:pt-12 md:px-16" fullWidthBgColor={block.darkBackground ? "bg-[#17181A]" : "bg-[#3A402B]"} data-sentry-element="ContentBlockServer" data-sentry-source-file="index.tsx">
        <h2 className="flex md:relative ml-8 md:ml-0 mb-4 text-5xl font-normal uppercase tracking-[-0.02em]">
          <ArrowLeftIcon className="hidden md:block mr-2 md:absolute w-9 rotate-180 -left-12 top-[6px] text-[#C7B592]" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="index.tsx" />
          <p dangerouslySetInnerHTML={{
          __html: block.headline
        }} />
        </h2>
        {/* <p className="mb-6 text-lg font-normal leading-[1.333rem] text-center">
          {block.subtitle}
         </p> */}
        <ul className="hidden grid-cols-4 gap-6 mt-2 mb-8 md:grid">
          {block?.icons?.map(icon => <SingleIconJustified key={icon.id} icon={icon} />)}
        </ul>
        <div className={`md:hidden mb-8 pl-8 w-full mt-2`}>
          <Swiper slidesPerView={1.2} spaceBetween={25} className={block.darkBackground ? styles.swiperContainerDark : styles.swiperContainer} slidesOffsetAfter={68} data-sentry-element="Swiper" data-sentry-source-file="index.tsx">
            {block?.icons?.map(icon => <SwiperSlide key={icon.id}>
                <div>
                  <SingleIconJustified icon={icon} />
                </div>
              </SwiperSlide>)}
          </Swiper>
        </div>

        {block.ctaContent && block.ctaUrl && <>
            <ButtonServer content={block.ctaContent} stretch={ButtonStretch.full} url={block.ctaUrl} intent={ButtonIntent.CTA} className="mb-4 md:hidden md:invisible" />
            <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.CTA} className="invisible hidden mb-4 md:block md:visible" />
          </>}
        <div className="mb-5 -ml-6 max-w-fit md:max-w-none md:-ml-12">
          <StarsWithReviews rating={parseFloat(block.starsRating as string) as RatingScores} reviews={block.reviewsAmount} overrideColor={"#1BB57C"} dark data-sentry-element="StarsWithReviews" data-sentry-source-file="index.tsx" />
        </div>
      </ContentBlockServer>
      <div className="absolute w-full -bottom-1 md:-bottom-4 xl:-bottom-7">
        <RippedPageSvg fill={block.darkBackground ? "#17181A" : "#3A402B"} data-sentry-element="RippedPageSvg" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};
export default IS_BAERSKIN ? ContentIconBlockServerJustified : ContentIconBlockServer;